.custom-buttons-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-btn {
  background: none;
  padding: 0;
  border: none;
  padding: 0 8px;
  position: relative;
  display: block;
  line-height: 1;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}



.custom-btn svg {
  position: relative;

}

.custom-btn.has-children svg {
  margin-right: 5px;
}


.custom-delete-btn {}

.custom-delete-btn svg {

  top: 2px;

}