.mega-dropdown {
  max-width: 100%;
}

.mega-dropdown ul {
  padding: 0px 0 0 0 !important;
  max-height: 250px;
  overflow-y: scroll;

}

.mega-dropdown ul input {
  margin-right: 7px;
  /*display: none;*/
}

.mega-dropdown .dropdown-toggle::after {
  content: '';
  display: none !important;
}

.mega-dropdown ul .tools {

  top: 0px;
  position: sticky;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: white;
  z-index: 9999;
}

.mega-dropdown ul span.check {
  width: 30px;
  display: inline-block;
  position: relative;
  top: -2px;
}


.mega-dropdown ul li {
  border-bottom: #d7e4bc;
  font-size: 0.8rem;
  cursor: pointer;
}


.mega-dropdown ul li {
  border-bottom: white 2px solid;
}

.mega-dropdown ul li:last-child {
  border-bottom: none
}

.mega-dropdown ul .selected {
  background-color: #eaf1dd;
}

.mega-dropdown span.round-pin {
  top: 2px
}