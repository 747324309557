.custom-range {
  position: relative;
}

.custom-range-track {
  height: 34px;
  position: relative;
  padding-top: 10px;
}

.custom-range-slider {
  width: 100%;
  height: 14px;
  background: lightgray;
  border-radius: 5px;
  position: relative;
  margin: 0 auto;
  cursor: pointer;
}

.custom-range-thumb {
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: blue;
  border-radius: 50%;
  transform: translateX(-5px) translateY(-5px);
  top: 0px;
  cursor: pointer;
  line-height: 24px;
  text-align: center;
  font-size: 0.7rem;
  color: white;
}

.custom-range-label {
  /* empecher le selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}