.App {
  text-align: center;
  height: 100vh;
}

.alert-sm {
  padding: 7px 10px;
  font-size: 0.9rem
}

.pointer {
  cursor: pointer;
}

small,
.small {
  font-size: 0.79rem;
}