.file-edit-wording {

  padding-top: 15px;
  background: white;
  display: flex;
  flex-direction: column;
  z-index: 9999;
}

.file-edit-wording .edit-icons {
  position: absolute;
  top: 3px;
  right: 0;
  padding: 0 5px 3px;
}

.file-edit-wording label {
  font-size: 0.75rem;
  color: #999
}


.file-edit-wording .edit-icons span {
  cursor: pointer;
  display: inline-block;
  padding: 0 5px;
  margin-left: 5px
}

.file-edit-wording input {
  line-height: 1.2;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 3px 7px;
}

.file-edit-wording textarea {
  line-height: 1.2;
  font-size: 0.85rem;
  min-height: 200px;

}