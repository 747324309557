.autocomplete-input {
  position: relative;

}

.autocomplete-input .autocomplete-container {
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  max-height: 200px;
  z-index: 9999;
  overflow-y: auto;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.autocomplete-input .suggestion {
  padding: 8px;
  cursor: pointer;
}

.autocomplete-input .suggestion:hover,
.autocomplete-input .suggestion.selected {
  background-color: #e0e0e0;
}