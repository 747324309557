.tree-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  line-height: 1.1;
}

.tree-form form {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.tree-form .nav-tabs {
  flex: 0;
}

.tree-form .tab-content {
  flex: 1;
  padding: 15px;
  border: 1px solid #eee;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

/*  -----------------
    PICTURES 
    -------------------  */
.tree-form .tree-pictures {}

.tree-form .tree-pictures .dropzone {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.tree-form .tree-pictures .dropzone .message {
  flex: 1;
}

.tree-form .tab-content {
  display: none;
}

.tree-form .tab-content.active {
  display: block;
}

.tree-form .tasks-list-row>div.select {
  flex: 0 0 50px;
}

.tree-form .tasks-list-row>div.categories {
  flex: 0 0 200px;
}

.tree-form .tasks-list-row>div.title {
  flex: 1;
}

.tree-form .tasks-list-row>div.text {
  flex: 1 0;
}

.tree-form .tasks-list-row>div.todo-on {
  /* DOCUMENTS */
  flex: 0 0 200px;
}

.tree-form .tasks-list-row>div.documents {
  /* Etat */
  flex: 0 0 100px;
}

.tree-form .tasks-list-row>div.state {
  /* Etat */
  flex: 0 0 200px;
}

.tree-form .tasks-list-row>div.func {
  flex: 0 0 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.tree-task {}

.tree-task textarea.text {
  height: 120px;
}