.tasks-list {}

.tasks-list-row {
  flex-wrap: wrap;

}

.tasks-list-row>div {
  padding: 7px 10px;
  border-left: 1px solid #eee;
  position: relative;
}


.tasks-list-header {
  background-color: #d7e4bc;
  font-weight: bold;
  text-align: center;
}

.tasks-list-header>div {

  text-align: center;
  border-color: white
}


.tasks-list-body .tasks-list-row {
  border-bottom: 1px solid #eee
}

.tasks-list-body .tasks-list-row:odd {
  background-color: pink;
}

.tasks-list-row.selected {
  background-color: #eaf1dd !important;
}


.tasks-list .react-datepicker {
  position: absolute;
  right: 0;
  top: 35px;
}

.tasks-list .react-datepicker input {
  border: none;
}


.tasks-list textarea.tskText {
  height: 100px;


}

.task-categories ul.currents {}

.task-categories ul.currents li {
  padding: 3px 5px;
  border-radius: 3px;
  background-color: #f0f0f0;
  margin: 0 0 5px 0;
  text-align: left;
}



.tasks-list textarea:read-only {
  overflow: hidden;


}