.uploader {}

.uploader .drop-zone {
  background-color: #fafafa;
  line-height: 1;
  text-align: center;
}

.uploader .large {
  flex: 1;
  padding: 25px;

}

.uploader .small {
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploader .btn-upload {
  background-color: transparent;
  border: none;
  font-size: 3rem;
  opacity: 0.7;
  transition: all 300ms;
  color: #436700;
}

.uploader .btn-upload:hover {
  transform: scale(1.2);
  opacity: 1;

}


.uploader .alert .btn-close {
  position: absolute;
  top: 10px;
  right: 10px
}