.navbar {
  padding: 0 25px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #f0f0f0;
}


.navbar .logo {
  flex: 0;
  height: 80px;
  margin-bottom: 10px;
}

.navbar .logo img {
  max-height: 100%;
}

.default-navbar {}

.default-navbar div.links {
  flex: 1
}

.default-navbar div.left-links {
  background-color: pink;
}

.default-navbar div.right-links {
  /* background-color: purple; */
  text-align: right;

}

.main-navbar {
  background-color: #d7e4bc;
}


.home-main .pictos {
  display: flex;
  list-style: none;
  padding: 15px 0 0 0;
  margin: 40px -15px;

}

.navbar .pictos li {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  margin: 15px 30px 0;

  font-size: 1rem;
  text-transform: uppercase;
  color: #666;

  cursor: pointer;

}

.navbar .pictos li i {
  font-size: 1.1rem;
  margin: 0 7px 0 0
}


.navbar .pictos li:hover {
  background-color: #c2d69a;
}