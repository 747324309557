.home {
  padding-top: 40px;
}

.home-main {}

.home-main .pictos {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 40px -15px;

}

.home-main .pictos li {
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  margin: 0 0 30px;
  border: 1px solid #aaa;
  font-size: 1rem;
  text-transform: uppercase;
  background-color: #fafafa;
  border-radius: 7px;
  cursor: pointer;
}

.home-main .pictos li i {
  font-size: 2rem
}



.home-main .pictos li:hover {
  background-color: #eaeaea;
}