#login {
  padding-top: 80px;
}

#login form {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}

#login button {
  margin: 5px 20px 15px;
}

#login button:disabled {
  opacity: 0.2;
  cursor: no-drop;
}