.main-map {
  display: flex;

}

.main-map .map {
  flex: 1;
  height: calc(100vh - 100px);

}

.main-map .left-side {
  background-color: #eaf1dd;
  padding: 40px 10px 10px;
  width: 52px;
  transition: width 500ms;
}

.left-side .toggle-sidebar i:before {
  transition: all 300ms;
}

.main-map .left-side.expanded {
  width: 180px;
}

/* toggle-sidebar */
.left-side .toggle-sidebar i {
  font-size: 2rem;
}

.left-side.minimized .toggle-sidebar i:before {
  transform: rotate(0.50turn);
}

/* sidebar-items */
.main-map .left-side .sidebar-item {
  display: flex;
  align-items: center;
  height: 60px;
}

.main-map .left-side .sidebar-item i {
  font-size: 1.7rem;
  margin: 0 7px;
  position: relative;
  top: -3px;
  transition: margin 300ms;
}

.main-map .left-side.minimized .sidebar-item i {
  margin: 0 0px;
}

.main-map .left-side .sidebar-item span {
  opacity: 1;
  transition: opacity 300ms;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.main-map .left-side.minimized .sidebar-item span {
  opacity: 0;
}

.main-map .map {
  position: relative;
}

.map-toolbar {
  position: absolute;
  background-color: #eaf1dd;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px 0px;
}

.map-toolbar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  margin: 0 -10px
}

.map-toolbar li {
  padding: 0 10px;
  margin: 0;
}

.map-toolbar li.tools button {
  opacity: 0.6;
  transition: all 300ms;
}

.map-toolbar li.tools button.selected {
  opacity: 1;
}