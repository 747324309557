/* forms */
.form-group {
  z-index: inherit;
  margin: 0 0 25px 0;
  position: relative;
}

.form-group-label {
  display: block;
  margin-bottom: 5px;
}

.form-control:read-only {

  border: 1px solid transparent !important;
  background-color: transparent !important;

}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
  border-color: inherit !important;
  box-shadow: none !important;
}

.btn {
  margin: 0 5px
}

.btn-xs {
  padding: 1px 5px;
  font-size: 0.7rem;
}


ul.nav-tabs {}

ul.nav-tabs li button {
  color: #999;

  background-color: #fafafa;
}

ul.nav-tabs li.active button {
  background-color: #eee;
  color: #111;
}

.react-datepicker {
  z-index: 999;
}

span.round-pin {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #eee;
}


/* Supprime les styles par défaut du handler */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #d7e4bc
}

/* Pour Firefox */
input[type="range"]::-moz-range-thumb {
  background-color: #d7e4bc
}


.react-datepicker {
  position: absolute !important;
  right: 0;
  top: 35px;
}