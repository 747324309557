.drawing-layer {
  height: 100%;
  width: 100%;
  user-select: none;
}


.drawing-layer-main,
.drawing-layer .sample-canvas {
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
}

.drawing-layer canvas {
  border: 0px solid purple;
  /* height: 100% !important;*/
}

/* TOOLBAR */





.drawing-layer-toolbar {
  position: fixed;
  top: 0;
  right: 0;
  height: auto;
  background-color: #eee;
  width: 40px;
  text-align: center;
  z-index: 9999;
}

.drawing-layer-toolbar div {
  margin: 5px 3px;
  border-bottom: 1px solid #999;
}

.drawing-layer-toolbar .drag-handler {
  height: 20px;
  background-color: #999;
  cursor: pointer;
  margin: 0 0 5px 0;
}


.drawing-layer-toolbar.is-dragging .drag-handler {
  cursor: move;
}


.drawing-layer-toolbar div:last-child {
  border-bottom: none;
}

.drawing-layer-toolbar div:first-child,
.drawing-layer-toolbar div:last-child {
  border-bottom: none;
}

.drawing-layer-toolbar button {
  color: #333;
  border-color: transparent;
  margin: 1px;
  border: none
}

.drawing-layer-toolbar button:hover {
  color: red;
}

.drawing-layer-toolbar button.jade {
  box-shadow: inset 4px 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid black;
}

.drawing-layer-toolbar .drawing-layer-toolbar .drag-handler {
  height: 10px;
  display: block;
  width: 100%;
  background-color: #333;
  margin: 5px 0px;
}

.drawing-layer-toolbar .thickness {
  width: 10px;
  height: 10px;
  display: inline-block;
  position: relative;
}

.drawing-layer-toolbar .thickness::before {
  content: " ";
  display: block;
  width: 15px;
  height: 2px;
  background-color: black;
  transform: translateY(-50%) rotate(-45deg);
  transform-origin: center;
  position: absolute;
  top: 50%;
  left: -3px;
}

.drawing-layer-toolbar .thickness-1::before {
  height: 2px;
}

.drawing-layer-toolbar .thickness-2::before {
  height: 4px;
}

.drawing-layer-toolbar .thickness-3::before {
  height: 6px;
}

.drawing-layer-toolbar input[type="color"] {
  width: 22px;
  height: 22px;
  padding: 0;
  border: none;
  cursor: pointer;
}

.drawing-layer-toolbar .btn-save {
  padding: 0 0;

}