.files-container {
  margin-bottom: 25px;
}

.files-container .files-items {
  margin-bottom: 25px;
}

.files-container.grid .files-items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}

.files-container.list .files-items {}

.files-container .files-items>div {
  position: relative;
}

.files-container .draggable-handler {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 8;
  padding: 0 2px 2px 1px;
  display: none;
  font-size: 1.7rem;
  background-color: #999;
  color: white;
  line-height: 1;
  border-radius: 7px;
  transition: all .2s ease-in-out;
}

.files-container .draggable-handler:hover {
  transform: scale(1.2);
  background-color: #666;
}

.files-container .draggable-handler svg {
  display: block;
}

.files-container .files-items>div:hover .draggable-handler {
  display: block;
}