.files-viewer {
  position: fixed;
  top: 1%;
  left: 1%;
  width: 98vw;
  height: 98vh;
  background-color: #111;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 9998;
  border-radius: 10px;
  color: #eee;
  line-height: 1;
}

.files-viewer .close {
  position: absolute;
  top: 0px;
  right: 15px;
  font-size: 3rem;
  color: #fff;
  line-height: 1;
  cursor: pointer;
  opacity: 0.5;
  transition: all 300ms;
  z-index: 9999;
}

.files-viewer .close:hover {
  opacity: 1;
}




/* Style des boutons de navigation (grosses flèches) */
.files-viewer .nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-size: 5rem;
  cursor: pointer;
  opacity: 0.7;
  transition: all 300ms;
}

.files-viewer .nav:hover {
  opacity: 1;
}

/* Style pour le bouton Précédent */
.files-viewer .nav.prev {
  left: 0px;
}

/* Style pour le bouton Suivant */
.files-viewer .nav.next {
  right: 0px;
}


.files-viewer .inner {
  height: 100%;
  position: relative;
  margin: 0;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

}


.files-viewer .content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 85%;
  border: 0px solid yellow;


}

.files-viewer .content div {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.files-viewer .image-wrapper img {

  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}


.files-viewer h2 {
  font-size: 18px;

  padding: 10px 35px 0;

  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  line-height: 1;
  padding: 5px;
  ;
}


.files-viewer .infos {
  height: 35px;
  width: 100%;
  padding: 0 25px;
  line-height: 34px;
  background-color: #aaa;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.files-viewer .infos ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.files-viewer .infos li {
  padding: 0;
  padding: 0 10px;
  border-right: 1px dotted #fafafa;
}

.files-viewer img {
  max-width: 100%;
  max-height: 100%;
  cursor: pointer !important;
}

.files-viewer iframe,

.files-viewer embed {
  width: 100%;
  height: 100%;
}