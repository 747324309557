.view-mode-toggler {
  line-height: 1;
  color: #999;
}

.view-mode-toggler span {
  display: inline-block;
  margin: 0 5px;
  padding: 1px;
  font-size: 1.8rem;
  opacity: 0.5;
  transition: all 300ms;
  cursor: pointer;
}

.view-mode-toggler span svg {
  position: relative;
  top: -2px
}

.view-mode-toggler .active {

  opacity: 1
}