.files-list-item>div {
  position: relative;
  border: 1px solid #ccc;
  padding: 4px;
  margin: 8px;


  display: flex;

}

.files-list-item.grid>div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
}

.files-list-item.list {

  flex: 1 0 100%;
  height: 130px;
  justify-content: space-between;
}

.files-list-item.grid img.cover {
  width: 180px;
  height: 180px;
  object-fit: cover;
}

.files-list-item.list img.cover {
  width: 120px;
  height: 120px;
  object-fit: cover;
  flex: 0 0 120px;
}

.files-list-item img.icon {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 32px;
}

.files-list-item.grid .name {
  position: absolute;
  top: 50%;
  left: 7px;
  right: 7px;
  max-height: 55%;
  overflow: hidden;
  transform: translateY(-50%);
  text-align: center;
  font-size: .8rem;
  background-color: #eee;
  padding: 7px 10px;
  word-wrap: break-word;
  opacity: 0;
  transition: all 300px;
}

.files-list-item.grid:hover .name {
  opacity: 1;
}

.files-list-item.list .wordings {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
  margin: 0 15px;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.files-list-item.list .wordings.editing {
  justify-content: flex-start;

}

.files-list-item.list .file-edit-wording {
  padding-top: 30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
}


.files-list-item.list .wordings.editing textarea {
  flex: 1;
}







.files-list-item.list h6 {
  padding: 0;
  margin: 0 0 5px;
  font-size: 0.9rem;
}

.files-list-item.list h6 span {
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

.files-list-item.list .comment {
  font-size: 0.85rem;
  line-height: 1.2;
  max-height: 60px;
  overflow: hidden;
  padding: 0 0 5px 0;
  color: #666;
}

.files-list-item img:hover {}

.files-list-item.list .infos {
  width: 180px;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.8rem;
}

.files-list-item.list li {
  margin: 3px 0;
}

.files-list-item .tools {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.files-list-item.grid .tools {
  position: absolute;
  bottom: 3px;
  left: 5px;
  right: 5px;
  background-color: rgba(220, 220, 220, 0.8);
  margin: 0;
  padding: 0 10px 5px;
}

.files-list-item.list .tools {
  width: 120px;
  align-items: center;
}

.files-list-item .tools li {
  margin: 0;
  padding: 0;
}


.files-list-item .tools span {


  cursor: pointer;
  display: block;
  font-size: 1rem;
  width: 24px;
  padding: 0;
  margin: 0;
  opacity: 0.7;
  transition: all 300ms;
  text-align: center;

}



.files-list-item .tools button:hover {
  opacity: 1;
}

.files-list-item.grid .edit-wordings-wrapper {
  position: absolute;
  top: 105%;
  left: -10px;
  right: -10px;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #999;

}

.files-list-item.grid .edit-wordings-wrapper input,
.files-list-item.grid .edit-wordings-wrapper textarea {
  width: 100%;
}