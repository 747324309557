.context-menu {
  position: absolute;
  width: auto;
  background-color: rgba(240, 240, 240, 0.9);
  padding: 0px 0px;
  border: 1px solid #aaa;
  border-radius: 7px;
}

.context-menu .close {
  font-size: 1.2rem;
  color: #666;
  padding-right: 7px;
  line-height: 0;
}

.context-menu ul {
  margin: 0 0 15px;
  padding: 0;
  list-style: none;
}

.context-menu li {
  margin: 0px 0;
  padding: 5px 7px;
  border-bottom: 1px dotted #eee;
  font-size: 0.72rem;
  line-height: 1.4;
  text-transform: uppercase;
  white-space: nowrap;
}

.context-menu li:hover {
  background-color: #fff;
}

.context-menu li i {
  margin: 0 3px 0 0;
  font-size: 110%
}

.context-menu .colors li {
  display: inline-block;
}

.context-menu .colors span {
  display: inline-block;
  height: 10px;
  width: 10px;
}