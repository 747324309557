.modal {
  background-color: rgba(127, 127, 127, 0.7);
}

.full-heigt {}

.modal-full-height .modal-content {
  height: 90vh;
}

.modal-body {
  overflow-y: scroll;
}

@media screen and (max-width: 1600px) {
  .modal-xl {
    max-width: 96%;
  }
}